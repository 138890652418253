import { ToastOptions } from 'react-toastify';

export enum TOAST_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export enum TOAST_POSITIONS {
  TOP_RIGHT = 'top-right'
}

export const TOAST_DEFAULT_POSITION = TOAST_POSITIONS.TOP_RIGHT;

export const TOAST_DEFAULT_AUTO_CLOSE_TIME = 3500;

export const TOAST_DEFAULT_OPTIONS: ToastOptions = {
  position: TOAST_DEFAULT_POSITION,
  autoClose: TOAST_DEFAULT_AUTO_CLOSE_TIME,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
};
