import type { AddEthereumChainParameter } from '@web3-react/types'

import BscIcon from '../assets/images/bsc-logo.png'
import MantaIcon from '../assets/images/manta-logo.png'
import ArbitrumIcon from '../assets/images/arbitrum.svg'

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }


export enum CHAIN_ID {
  BSC = 56,
  MANTA = 169,
  ARBITRUM = 42161,
}

export const DEFAULT_CHAIN_ID = CHAIN_ID.MANTA;

export const AVAILABLE_CHAINS = [CHAIN_ID.BSC, CHAIN_ID.MANTA, CHAIN_ID.ARBITRUM];

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Binance Coin',
  symbol: 'BNB',
  decimals: 18,
}

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

export const CHAINS_RPC = {
  [CHAIN_ID.BSC]: ['https://bsc.publicnode.com', 'https://1rpc.io/bnb', 'https://rpc.ankr.com/bsc', 'https://bsc-dataseed.binance.org'],
  [CHAIN_ID.MANTA]: ['https://manta.nirvanalabs.xyz/mantapublic', 'https://1rpc.io/manta'],
  [CHAIN_ID.ARBITRUM]: ['https://arbitrum-one.publicnode.com'],
};

export const MAINNET_CHAINS: ChainConfig = {
  [CHAIN_ID.BSC]: {
    urls: CHAINS_RPC[CHAIN_ID.BSC],
    name: 'BNB Chain',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [CHAIN_ID.MANTA]: {
    urls: CHAINS_RPC[CHAIN_ID.MANTA],
    name: 'Manta Pacific Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://pacific-explorer.manta.network'],
  },
  [CHAIN_ID.ARBITRUM]: {
    urls: CHAINS_RPC[CHAIN_ID.ARBITRUM],
    name: 'Arbitrum One Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  },
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)

export const CHAIN_NAME = {
  [CHAIN_ID.BSC]: 'BSC',
  [CHAIN_ID.MANTA]: 'Manta',
  [CHAIN_ID.ARBITRUM]: 'Arbitrum',
}

export const CHAIN_ICON = {
  [CHAIN_ID.BSC]: BscIcon,
  [CHAIN_ID.MANTA]: MantaIcon,
  [CHAIN_ID.ARBITRUM]: ArbitrumIcon,
}

export const GAS_MULTIPLIER = {
  [CHAIN_ID.MANTA]: 1.3,
};

export const GAS_PRICE_MULTIPLIER = {
  [CHAIN_ID.MANTA]: 1.1,
};

export const MULTI_CALL = '0x4bfd0651b8a549c44e0f57e293149247203ae399';
