import React from 'react'
import styled from 'styled-components'
import { Card } from 'sparkswap-uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 640px;
  width: 100%;
  z-index: 5;
`

export const CardWrapper = styled.div`
  max-width: 640px;
  width: 100%;
`;

export const Box = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
