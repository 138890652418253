import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Popups from '../components/Popups'
import Bridge from './Bridge/bridge'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Menu from '../components/Menu'
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../style/toast.css';
import {
  RedirectDuplicateTokenIds as RedirectDuplicateTokenIdsNew,
  RedirectOldAddLiquidityPathStructure as RedirectOldAddLiquidityPathStructureNew,
  RedirectToAddLiquidity as RedirectToAddLiquidityNew,
} from './AddLiquidity/redirects'
import AddLiquidityNew from './AddLiquidity'
import SwapNew from './Swap'
import PoolNew from './Pool'
import Ceto404 from './Ceto404'
import PoolFinderNew from './PoolFinder'
import RemoveLiquidityNew from './RemoveLiquidity'
import {
  RedirectOldRemoveLiquidityPathStructure as RedirectOldRemoveLiquidityPathStructureNew,
} from './RemoveLiquidity/redirects'
import Page from '../components/layout/Page'

import {
  ADD_CURRENCY_ID_A,
  ADD_CURRENCY_ID_A_AND_B,
  ADD_PATH,
  BRIDGE_PATH,
  CETO_404_PATH,
  CREATE_PATH,
  FIND_PATH,
  POOL_PATH,
  REMOVE_CURRENCY_ID_A_AND_B,
  REMOVE_TOKENS_PATH,
  SEND_PATH,
  SWAP_OUTPUT_CURRENCY_PATH,
  SWAP_PATH,
} from '../constants/routes'
import Timer from '../components/Timer'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`


const Marginer = styled.div`
  margin-top: 5rem;
`

export const TIMER = '1713906000' //  05.04.2024 15.00 UTC

export default function App() {

  return (
    <>
      <Timer remainTime={TIMER} />
      <Menu>
        <Suspense fallback={null}>
        <AppWrapper>
            <Page>
              <Popups />
                <Switch>
                  <Route exact strict path={SWAP_PATH} component={SwapNew} />
                  <Route exact strict path={CETO_404_PATH} component={Ceto404} />
                  <Route exact strict path={SWAP_OUTPUT_CURRENCY_PATH} component={RedirectToSwap} />
                  <Route exact strict path={SEND_PATH} component={RedirectPathToSwapOnly} />
                  <Route exact strict path={BRIDGE_PATH} component={Bridge} />
                  <Route exact strict path={FIND_PATH} component={PoolFinderNew} />
                  <Route exact strict path={POOL_PATH} component={PoolNew} />
                  <Route exact strict path={CREATE_PATH} component={RedirectToAddLiquidityNew} />
                  <Route exact path={ADD_PATH} component={AddLiquidityNew} />
                  <Route exact path={ADD_CURRENCY_ID_A} component={RedirectOldAddLiquidityPathStructureNew} />
                  <Route exact path={ADD_CURRENCY_ID_A_AND_B} component={RedirectDuplicateTokenIdsNew} />
                  <Route exact strict path={REMOVE_TOKENS_PATH} component={RedirectOldRemoveLiquidityPathStructureNew} />
                  <Route exact strict path={REMOVE_CURRENCY_ID_A_AND_B} component={RemoveLiquidityNew} />
                  <Route component={RedirectPathToSwapOnly} />
                </Switch>
              <Marginer />
            </Page>
           </AppWrapper>
         </Suspense>
      </Menu>
    </>
  )
}
