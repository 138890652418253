import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import { BRIDGE_ABI, BRIDGE_ADDRESS_ARBITRUM, BRIDGE_ADDRESS_BSC, BRIDGE_ADDRESS_MANTA } from 'constants/abis/bridge'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { CHAIN_ID } from '../constants/chain'
import useWeb3 from './useWeb3'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library: provider, account } = useWeb3()

  return useMemo(() => {
    if (!address || !ABI || !provider) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account])
}


export const BRIDGE_ADDRESSES = {
  [CHAIN_ID.BSC]: BRIDGE_ADDRESS_BSC,
  [CHAIN_ID.MANTA]: BRIDGE_ADDRESS_MANTA,
  [CHAIN_ID.ARBITRUM]: BRIDGE_ADDRESS_ARBITRUM,
}

export function useBridgeContract(): Contract | null {
  const { chainId } = useWeb3()
  return useContract(chainId ? BRIDGE_ADDRESSES[chainId] : null, BRIDGE_ABI, true)
}


export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3()
  let address: string | undefined
  if (chainId) {
    switch (Number(chainId)) {
    case 169:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}


export function useMulticallContract(): Contract | null {
  const { chainId } = useWeb3()
  return useContract(chainId ? MULTICALL_NETWORKS[chainId] : undefined, MULTICALL_ABI, false)
}
