import { Token } from 'pulsex-sdk'
import { CHAIN_ID } from '../../constants/chain'
import { TOKEN_SYMBOL, TOKENS } from '../../constants'

import DexSwapAbi from '../../constants/abis/DexSwapRouterAbi.json'
import PancakeRouterAbi from '../../constants/abis/PancakeRouterAbi.json'

export enum ROUTER_TYPE {
  CETO_SWAP,
  PANCAKE,
  DEX_SWAP ,
}

export const ROUTERS = {
  [ROUTER_TYPE.CETO_SWAP] : {
    address: '0x882EE78CBA6676de8522f2c34bD08BacbCAEe813',
    abi: DexSwapAbi,
  },
  [ROUTER_TYPE.PANCAKE] : {
    address: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    abi: PancakeRouterAbi,
  },
  [ROUTER_TYPE.DEX_SWAP] : {
    address: '0x6513b561c61d6032D0Ac2dB54739DFaFb8381d62',
    abi: DexSwapAbi,
  },
}

export const ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS = [ROUTER_TYPE.CETO_SWAP, ROUTER_TYPE.DEX_SWAP];

interface BridgeToken {
  isBridge: boolean;
  payInNative: boolean;
  bridgeTokenId: number;
  fromSwapPath: string[];
  toSwapPath: string[];
  fromRouterType: ROUTER_TYPE;
  toRouterType: ROUTER_TYPE;
}

interface ToTokensMap {
  [chainId: number]: Token[];
}

interface BridgeTokensMap {
  [chainId: number]: {
    [tokenAddress: string]: BridgeToken;
  };
}

interface TokenConfig {
  isNative: boolean;
  toChains: CHAIN_ID[];
  toTokens: ToTokensMap;
  bridgeTokensMap: BridgeTokensMap;
}

type Config = Record<number, Record<string, TokenConfig>>


const CONFIG: Config = {
  // [CHAIN_ID.BSC]: {
  //   // [TOKEN_SYMBOL.TRUTH]: {
  //   //   isNative: false,
  //   //   toChains: [CHAIN_ID.MANTA],
  //   //   toTokens: {
  //   //     [CHAIN_ID.MANTA] : [
  //   //       TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.STGAI],
  //   //     ]
  //   //   },
  //   //   bridgeTokensMap: {
  //   //     [CHAIN_ID.MANTA]: {
  //   //       [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.STGAI].address]: {
  //   //         isBridge: true,
  //   //         payInNative: false,
  //   //         bridgeTokenId: 2,
  //   //         fromSwapPath: [],
  //   //         toSwapPath: [],
  //   //         fromRouterType: ROUTER_TYPE.PANCAKE,
  //   //         toRouterType: ROUTER_TYPE.CETO_SWAP,
  //   //       },
  //   //     },
  //   //   },
  //   // },
  //   [TOKEN_SYMBOL.USDC]: {
  //     isNative: false,
  //     toChains: [CHAIN_ID.MANTA],
  //     toTokens: {
  //       [CHAIN_ID.MANTA] : [
  //         TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS],
  //         TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC],
  //         TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO],
  //         TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH],
  //       ]
  //     },
  //     bridgeTokensMap: {
  //       [CHAIN_ID.MANTA]: {
  //         [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address]: {
  //           isBridge: true,
  //           payInNative: false,
  //           bridgeTokenId: 1,
  //           fromSwapPath: [],
  //           toSwapPath: [],
  //           fromRouterType: ROUTER_TYPE.PANCAKE,
  //           toRouterType: ROUTER_TYPE.CETO_SWAP,
  //         },
  //         [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address]: {
  //           isBridge: false,
  //           payInNative: false,
  //           bridgeTokenId: 1,
  //           fromSwapPath: [],
  //           toSwapPath: [
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address,
  //           ],
  //           fromRouterType: ROUTER_TYPE.PANCAKE,
  //           toRouterType: ROUTER_TYPE.CETO_SWAP,
  //         },
  //         [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address]: {
  //           isBridge: false,
  //           payInNative: false,
  //           bridgeTokenId: 1,
  //           fromSwapPath: [],
  //           toSwapPath: [
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address,
  //           ],
  //           fromRouterType: ROUTER_TYPE.PANCAKE,
  //           toRouterType: ROUTER_TYPE.CETO_SWAP,
  //         },
  //         [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH].address]: {
  //           isBridge: false,
  //           payInNative: true,
  //           bridgeTokenId: 1,
  //           fromSwapPath: [],
  //           toSwapPath: [
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address,
  //             TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH].address,
  //           ],
  //           fromRouterType: ROUTER_TYPE.PANCAKE,
  //           toRouterType: ROUTER_TYPE.CETO_SWAP,
  //         },
  //       },
  //     },
  //   },
  // },
  [CHAIN_ID.MANTA]: {
    [TOKEN_SYMBOL.USDEX_PLUS]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM],
      toTokens: {
        [CHAIN_ID.ARBITRUM] : [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
        // [CHAIN_ID.BSC] : [
        //   TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.USDC],
        // ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.CETO_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
            ],
            fromRouterType: ROUTER_TYPE.CETO_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
        // [CHAIN_ID.BSC]: {
        //   [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.USDC].address]: {
        //     isBridge: true,
        //     payInNative: false,
        //     bridgeTokenId: 1,
        //     fromSwapPath: [],
        //     toSwapPath: [],
        //     fromRouterType: ROUTER_TYPE.CETO_SWAP,
        //     toRouterType: ROUTER_TYPE.PANCAKE,
        //   },
        // },
      },
    },
    [TOKEN_SYMBOL.USDC]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM],
      toTokens: {
        [CHAIN_ID.ARBITRUM] : [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
        // [CHAIN_ID.BSC] : [
        //   TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.USDC],
        // ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.CETO_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
            ],
            fromRouterType: ROUTER_TYPE.CETO_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
        // [CHAIN_ID.BSC]: {
        //   [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.USDC].address]: {
        //     isBridge: false,
        //     payInNative: false,
        //     bridgeTokenId: 1,
        //     fromSwapPath: [
        //       TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address,
        //       TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
        //     ],
        //     toSwapPath: [],
        //     fromRouterType: ROUTER_TYPE.CETO_SWAP,
        //     toRouterType: ROUTER_TYPE.PANCAKE,
        //   },
        // }
      },
    },
  },
  [CHAIN_ID.ARBITRUM]: {
    [TOKEN_SYMBOL.USDEX_PLUS]: {
      isNative: false,
      toChains: [CHAIN_ID.MANTA],
      toTokens: {
        [CHAIN_ID.MANTA] : [
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO],
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH],
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC],
        ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.MANTA]: {
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address,
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH].address]: {
            isBridge: false,
            payInNative: true,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH].address,
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address,
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
        },
      },
    },
    [TOKEN_SYMBOL.USDC]: {
      isNative: false,
      toChains: [CHAIN_ID.MANTA],
      toTokens: {
        [CHAIN_ID.MANTA] : [
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC],
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO],
          TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH],
        ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.MANTA]: {
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDC].address,
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address,
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
          [TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH].address]: {
            isBridge: false,
            payInNative: true,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.CETO].address,
              TOKENS[CHAIN_ID.MANTA][TOKEN_SYMBOL.ETH].address,
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.CETO_SWAP,
          },
        },
      },
    },
  },
}


// TODO: change on production on CONFIG
// export default TEST_CONFIG;

// TODO: PROD
export default CONFIG;
