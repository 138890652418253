import { useCallback, useEffect, useMemo, useState } from 'react'
import { Token } from 'pulsex-sdk'
import { getDataByChainId, getDataByToken, switchDataByChainId } from '../helpers/bridge'
import CONFIG from '../config'
import { AVAILABLE_CHAINS, CHAIN_ID } from '../../../constants/chain'
import { DEFAULT_FROM_CHAIN_ID, DEFAULT_TO_CHAIN_ID } from '../constants/common'
import { BridgeData, BridgeDataFrom, BridgeState } from '../types/bridge'
import useWeb3 from '../../../hooks/useWeb3'

type useBridgeData = {
  from: BridgeDataFrom
  to: BridgeData
  onSwitchChains: () => Promise<void>
}

const useBridgeData = (): useBridgeData => {
  const { chainId: activeChainId, connector } = useWeb3()

  const [from, setFrom] = useState<BridgeState>({
    chain: activeChainId || DEFAULT_FROM_CHAIN_ID,
    chains: [],
    token: null,
    tokens: [],
  })

  const [to, setTo] = useState<BridgeState>({
    // chain: from.chain === CHAIN_ID.PULSE_CHAIN ? CHAIN_ID.BSC : DEFAULT_TO_CHAIN_ID,
    chain: from.chain === CHAIN_ID.MANTA ? CHAIN_ID.BSC : DEFAULT_TO_CHAIN_ID,
    chains: [],
    token: null,
    tokens: [],
  })

  const onConnect = async (chainId: CHAIN_ID) => {
    await connector.activate(chainId)
  }

  const onFromChain = async (chainId: CHAIN_ID) => {
    try {
      await onConnect(chainId)

      const { from, to } = getDataByChainId(chainId)

      setFrom({
        chain: from.chain,
        chains: from.chains,
        token: from.token,
        tokens: from.tokens,
      })

      setTo({
        chain: to.chain,
        chains: to.chains,
        token: to.token,
        tokens: to.tokens,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const onToChain = (chainId: CHAIN_ID) => {
    const toChain = chainId

    if (!from.token?.symbol) return

    const toTokens = CONFIG[from.chain][from.token.symbol].toTokens[toChain]
    const toToken = toTokens[0]

    setTo((prevState) => ({
      ...prevState,
      chain: toChain,
      token: toToken,
      tokens: toTokens,
    }))
  }

  const onChangeTokenFrom = useCallback((token: Token) => {

    const { to: toObj } = getDataByToken(token, from.chain, to.chain, to.token)

    setFrom((prevState) => ({
      ...prevState,
      token,
    }))

    setTo({
      chain: toObj.chain,
      chains: toObj.chains,
      token: toObj.token,
      tokens: toObj.tokens,
    })
  }, [from, to])

  const onChangeTokenTo = (token: Token) => {
    setTo((prevState) => ({
      ...prevState,
      token,
    }))
  }

  const onSwitchChains = async () => {
    try {
      await onConnect(to.chain)

      const { from: fromObj, to: toObj } = switchDataByChainId(from, to)

      setFrom({
        chain: fromObj.chain,
        chains: fromObj.chains,
        token: fromObj.token,
        tokens: fromObj.tokens,
      })

      setTo({
        chain: toObj.chain,
        chains: toObj.chains,
        token: toObj.token,
        tokens: toObj.tokens,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fromChainId = activeChainId || from.chain

    if (
      AVAILABLE_CHAINS.includes(fromChainId) &&
      ((!from.token && !to.token) || (fromChainId !== Number(from.chain)))
    ) {
      const { from: fromObj, to } = getDataByChainId(fromChainId)
      setFrom({
        chain: fromObj.chain,
        chains: fromObj.chains,
        tokens: fromObj.tokens,
        token: fromObj.token,
      })

      setTo({
        chain: to.chain,
        chains: to.chains,
        token: to.token,
        tokens: to.tokens,
      })
    }
  }, [from, to, activeChainId])


  const bridgeTokenInfo = useMemo(() => {
    if (!AVAILABLE_CHAINS.includes(from.chain)) return {
      bridgeTokenId: -1,
      isBridge: false,
      payInNative: false,
      isNative: false,
      fromSwapPath: []
    }

    const currentObj = CONFIG[from.chain][from?.token?.symbol || ''];

    if (!currentObj || !to.chain || !to.token?.address) return {
      bridgeTokenId: -1,
      isBridge: false,
      payInNative: false,
      isNative: false,
      fromSwapPath: []
    }

    const bridgeTokensMap = currentObj.bridgeTokensMap[to.chain];

    const bridgeToken = bridgeTokensMap && bridgeTokensMap[to.token?.address];
    const fromSwapPath = bridgeTokensMap && bridgeToken ? bridgeToken.fromSwapPath : [];

    return {
      bridgeTokenId: bridgeToken ? bridgeToken.bridgeTokenId : -1,
      isBridge: bridgeToken?.isBridge,
      payInNative: bridgeToken?.payInNative,
      isNative: currentObj?.isNative,
      fromSwapPath,
    };
  }, [from, to])


  return {
    from: {
      ...from,
      bridgeTokenId: bridgeTokenInfo.bridgeTokenId,
      isBridge: bridgeTokenInfo.isBridge,
      fromSwapPath: bridgeTokenInfo.fromSwapPath,
      payInNative: bridgeTokenInfo.payInNative,
      isNative: bridgeTokenInfo.isNative,
      onChangeChain: onFromChain,
      onChangeToken: onChangeTokenFrom,
    },
    to: {
      ...to,
      onChangeChain: onToChain,
      onChangeToken: onChangeTokenTo,
    },
    onSwitchChains,
  }
}

export default useBridgeData
