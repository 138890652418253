import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from 'sparkswap-uikit'

const StyledNav = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > div {
    margin-bottom: 8px;
    text-align: center;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 3px;
    background: transparent;

    > a {
        width: 50%;
    }
  }
`

const Nav = ({ activeIndex = 0 }: { activeIndex?: number }) => (
  <StyledNav>
    <ButtonMenu activeIndex={activeIndex} size="sm" variant="subtle">
      <ButtonMenuItem id="swap-nav-link" size="sm" to="/swap" as={Link}>
       Swap
      </ButtonMenuItem>
      <ButtonMenuItem id="pool-nav-link" size="sm" to="/pool" as={Link}>
       Liquidity
      </ButtonMenuItem>
    </ButtonMenu>
  </StyledNav>
)

export default Nav
