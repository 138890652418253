import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js'
import { MultiCall } from 'multicall.js';
import Web3 from 'web3'

import { makeContract } from '../../Bridge/utils/contract.utils';

import useWeb3 from '../../../hooks/useWeb3';

import { toBN } from '../../Bridge/utils/formaters.utils';
import { isAddressesEqual } from '../../../utils';

import { DEFAULT_CHAIN_ID, MULTI_CALL } from '../../../constants/chain'
import {
  G_CETO_404_ABI,
  REVENUE_DISTRIBUTOR_ABI,
  REVENUE_DISTRIBUTOR,
  REVENUE_DISTRIBUTOR_NEW_ABI,
  NEW_REVENUE_DISTRIBUTOR
} from '../../../constants/abis/ceto404';
import WHITELIST from '../../../constants/distributorWhitelist';
import { G_CETO, CETO, TOKEN_SYMBOL, USDC } from '../../../constants'
import  erc20Abi from '../../../constants/abis/erc20.json'
import { CLAIM_TOKENS, LP_LIST } from '../constants/common.constants'

import useGetTokenPrice from '../../../hooks/useGetTokenPrice'


export interface Ceto404Types {
  totalSupply: BigNumber | null,
  marketCap: BigNumber | null,
  price: BigNumber| null,
  tokenIds: string[],
  nftClassesById: { [value: string]: string[] }
  availableToClaimTokens:  {
    [value: string]: {
      amount: BigNumber,
      amountUsd: BigNumber,
      symbol: string,
    }
  }
}

const use404Info = () => {
  const [info, setInfo] = useState({
    totalSupply: null,
    marketCap: null,
    price: null,
    tokenIds: null,
    nftClassesById: null,
    availableToClaimTokens: null,
  });
  const [oldInfo, setOldInfo] = useState({
    tokenIds: null,
    availableToClaimTokens: null,
  })
  const { chainId, account, libraryByChainId } = useWeb3()

  const prices = useGetTokenPrice(Object.values(CLAIM_TOKENS));

  const getLpInfo = useCallback(async () => {
    const web3 = new Web3(libraryByChainId(DEFAULT_CHAIN_ID).provider);
    const multiCall = new MultiCall(web3, MULTI_CALL);

    const lpAddresses = Object.keys(LP_LIST);

    const lpContracts = lpAddresses.map(contract => (
      makeContract(libraryByChainId(DEFAULT_CHAIN_ID), erc20Abi, contract)
    ));

    const quoteTokensContracts = lpAddresses.map(item => (
      makeContract(libraryByChainId(DEFAULT_CHAIN_ID), erc20Abi, LP_LIST[item].quoteToken)
    ))

    const [lpsTotalSupplyResult] = await multiCall.all([
      lpContracts.map((item) => ({
        totalSupply: item.methods.totalSupply(),
      })),
    ]);

    const [quoteTokensResult] = await multiCall.all([
      quoteTokensContracts.map((item, index) => ({
        lpBalance: item.methods.balanceOf(lpAddresses[index]),
        decimals: item.methods.decimals(),
        symbol: item.methods.symbol(),
      })),
    ]);

    return lpAddresses.reduce((acc,item, index) => ({
      ...acc,
      [item]: {
        totalSupply: lpsTotalSupplyResult[index].totalSupply,
        quoteTokenBalanceLP: quoteTokensResult[index].lpBalance,
        quoteDecimals: quoteTokensResult[index].decimals,
        quoteSymbol: quoteTokensResult[index].symbol,
        symbol: LP_LIST[item].symbol,
      },
    }), {})

  },[]);

  const getClaimTokensPrice = useCallback(async (availableToClaimTokens)=> {
    const lpInfo = await getLpInfo();

    return Object.keys(availableToClaimTokens).reduce((acc, item) => {
      const amount = availableToClaimTokens[item];

      if (Object.keys(LP_LIST).find(address => isAddressesEqual(address, item))) {
        const lpAddress = Object.keys(lpInfo).find(address => isAddressesEqual(address, item));
        const lp = lpInfo[lpAddress];
        const quoteTokenPrice = prices[lp.quoteSymbol] || toBN(1);
        const lpRatio = amount.div(lp.totalSupply);
        const lpTotalInQuoteToken = toBN(lp.quoteTokenBalanceLP, lp.quoteDecimals)
          .times(2)
          .times(lpRatio);


        return {
          ...acc,
          [item]: {
            amount: toBN(amount, 18),
            amountUsd: lpTotalInQuoteToken.times(quoteTokenPrice),
            symbol: lp.symbol,
          }
        }
      }

      const tokenAddress = Object.keys(CLAIM_TOKENS).find(address => isAddressesEqual(address, item));
      const token = CLAIM_TOKENS[tokenAddress] || USDC;
      const tokenPrice = token.symbol !== USDC.symbol ? prices[token.symbol] : toBN(1)

      return {
        ...acc,
        [item]: {
          amount: toBN(amount, token.decimals),
          amountUsd: toBN(amount, token.decimals).times(tokenPrice),
          symbol: token.symbol,
        }
      }
    }, {})
  },[prices]);

  const get404Info = useCallback(async () => {
    const web3 = new Web3(libraryByChainId(DEFAULT_CHAIN_ID).provider);
    const multiCall = new MultiCall(web3, MULTI_CALL);
    const contract = makeContract(libraryByChainId(DEFAULT_CHAIN_ID), G_CETO_404_ABI, G_CETO.address);
    const revenueContract = makeContract(libraryByChainId(DEFAULT_CHAIN_ID), REVENUE_DISTRIBUTOR_ABI, REVENUE_DISTRIBUTOR);
    const newRevenueContract = makeContract(libraryByChainId(DEFAULT_CHAIN_ID), REVENUE_DISTRIBUTOR_NEW_ABI, NEW_REVENUE_DISTRIBUTOR);

    const totalSupply = await contract.methods.totalSupply().call();

    setInfo(prevState => ({
      ...prevState,
      totalSupply: toBN(totalSupply),
      price: prices[TOKEN_SYMBOL.GCETO],
      marketCap: prices[TOKEN_SYMBOL.GCETO] ? prices[TOKEN_SYMBOL.GCETO].times(totalSupply) : null
    }))

    if(!account) {
      return;
    }

    const tokenIds = await contract.methods.owned(account).call();

    const [nftClassesResult] = await multiCall.all([
      tokenIds.map((id) => ({
        nftClasses: contract.methods.nftClasses(id),
      })),
    ]);

    const nftClasses =  nftClassesResult.map(item => item.nftClasses);

    const nftClassesById = tokenIds.reduce((acc, item, index) => ({
      ...acc, [item]: nftClasses[index]
    }), {});

    const availableToClaimes = await Promise.all(
      [...tokenIds, 0].map(async (item) => {
        return newRevenueContract.methods.availableToClaim(item).call({ account });
      })
    );

    const availableToClaimArray = availableToClaimes.flat()

    const availableToClaimTokensResult = availableToClaimArray.reduce((acc, item) => {
      if (!acc[item[0]]) {
        return { ...acc, [item[0]] : toBN(item[1]) }
      }

      return { ...acc, [item[0]] : toBN(item[1]).plus(acc[item[0]]) }
    }, { })

    const availableToClaimTokens = await getClaimTokensPrice(availableToClaimTokensResult);

    const oldAvailableToClaimTokenIds = WHITELIST.filter(item => tokenIds.includes(item))

    console.log('oldAvailableToClaimTokenIds', oldAvailableToClaimTokenIds)
    console.log('new')

    if (oldAvailableToClaimTokenIds.length) {
      const availableToClaimesOld = await Promise.all(
        oldAvailableToClaimTokenIds.map(async (item) => {
          return revenueContract.methods.availableToClaim(item).call({ account });
        })
      );

      const availableToClaimOldArray = availableToClaimesOld.flat()

      const availableToClaimTokensOldResult = availableToClaimOldArray.reduce((acc, item) => {
        if (!acc[item[0]]) {
          return { ...acc, [item[0]] : toBN(item[1]) }
        }

        return { ...acc, [item[0]] : toBN(item[1]).plus(acc[item[0]]) }
      }, { })

      const availableToClaimTokensOld = await getClaimTokensPrice(availableToClaimTokensOldResult);
      setOldInfo({
        tokenIds: oldAvailableToClaimTokenIds,
        availableToClaimTokens: availableToClaimTokensOld,
      })
    }

    setInfo(prevState => ({
      ...prevState,
      tokenIds: tokenIds,
      nftClassesById: nftClassesById,
      availableToClaimTokens: availableToClaimTokens,
    }));
  },[chainId, account, prices])

  useEffect(() => {
    if (prices) {
      (async () => {
        await get404Info();
      })()
    }
  }, [chainId, account, prices])

  return {
    update404Info: get404Info,
    info: info,
    oldInfo,
  };
}

export default use404Info;