import React from 'react'
import { Heading, Text } from 'sparkswap-uikit'

import styled from 'styled-components'

import useWeb3 from '../../hooks/useWeb3'

import { DEFAULT_CHAIN_ID } from '../../constants/chain'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0;
  text-align: center;
`;

const ModalWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  right:  0;
  bottom:  0;
  left:  0;
`;

const ModalBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right:  0;
  bottom:  0;
  left:  0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 530px;
  height: 100%;
`;

const ModalCard = styled.div`
  background: #050317;
  border-radius: 5px;
  padding: 24px;
`;

const Button = styled.button`
  background: #1F17F7;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  padding: 10px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  min-height: 42px;
  font-size: 14px;
`;

const WrongNetworkModal = () => {
  const { connector } = useWeb3();

  const onSwitch = async () => {
    await connector.activate(DEFAULT_CHAIN_ID);
  }

  return (
    <ModalWrap>
      <ModalBackdrop>
        <Container>
          <ModalCard>
            <Heading color='#fff'>Warning</Heading>
            <Content>
              <Heading color='#D501BB'>CETO SWAP operates on Manta network</Heading>
              <Text fontSize='16px' color='#fff'>Please switch the chain</Text>
            </Content>
            <Button onClick={onSwitch}>Switch</Button>
          </ModalCard>
        </Container>
      </ModalBackdrop>
    </ModalWrap>
  )
}

export default WrongNetworkModal
