import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
  padding: 0 15px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 30px;
  }
`

export default Container
