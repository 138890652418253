import React, { useCallback, FC, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Card, Heading, Text, useMatchBreakpoints } from 'sparkswap-uikit'
import BigNumber from 'bignumber.js'

import SkeletonLoader from '../../../../components/SkeletonLoader';

import { dFlex } from '../../../../style/Global'
import useWeb3 from '../../../../hooks/useWeb3'

import useClaim from '../../hooks/useClaim'

import { Ceto404Types } from '../../hooks/use404Info'
import { formatBNWithCommas, toBN } from '../../../Bridge/utils/formaters.utils'
import QuestionHelper from '../../../../components/QuestionHelper'

import useStyles from './styles.module';



export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-bottom: 32px;
`


export const TextSymbol = styled.div`
  color: ${({ theme }) => theme.colors.secondaryBright};
`

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`


interface Props {
  gcetoInfo: Ceto404Types,
  update404Info: () => Promise<void>
  oldInfo: {
    tokenIds: string[],
    availableToClaimTokens:  {
      [value: string]: {
        amount: BigNumber,
        amountUsd: BigNumber,
        symbol: string,
      }
    }
  }
}

const Claim: FC<Props> = ({ gcetoInfo, oldInfo, update404Info }) => {
  const state = useMatchBreakpoints();
  const { account } = useWeb3()
  const { classes } = useStyles()

  const { claim, loading} = useClaim();

  const sumAmountUsd = useMemo(() => (
    gcetoInfo.availableToClaimTokens
      ? Object.keys(gcetoInfo.availableToClaimTokens).reduce((acc, item) => (
        acc.plus(gcetoInfo.availableToClaimTokens[item].amountUsd)
      ), toBN(0))
      : toBN(0)
  ), [gcetoInfo]);


  const oldSumAmountUsd = useMemo(() => (
    oldInfo.availableToClaimTokens
      ? Object.keys(oldInfo.availableToClaimTokens).reduce((acc, item) => (
        acc.plus(oldInfo.availableToClaimTokens[item].amountUsd)
      ), toBN(0))
      : toBN(0)
  ), [oldInfo]);

  const handleClaim = useCallback(async () => {
    await claim(gcetoInfo.tokenIds);
    await update404Info();
  }, [gcetoInfo.tokenIds])

  const handleClaimOld = useCallback(async () => {
    await claim(oldInfo.tokenIds, true);
    await update404Info();
  }, [gcetoInfo.tokenIds])

  return (
    <Card
      padding={24}
      marginTop={30}
      style={{ zIndex: 2, ...dFlex('space-between', 'initial'), flexDirection: 'column' }}
    >
      {
        oldSumAmountUsd.gt(0)
          ? (
            <>
              <Content>
                <Text fontSize='24px'>Your 404 rewards</Text>
                {
                  !oldInfo.availableToClaimTokens && account
                    ? <SkeletonLoader className={classes.loader}/>
                    : (
                      <Heading
                        color='secondaryBright' size={state.isXl || state.isXxl ? 'xl' : 'lg'}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        ${formatBNWithCommas(oldSumAmountUsd, 4, 0, true)}
                        {
                          !oldSumAmountUsd.isZero() && (
                            <QuestionHelper text={<ClaimValues availableToClaimTokens={oldInfo.availableToClaimTokens}/>}/>
                          )
                        }
                      </Heading>
                    )
                }
              </Content>

              <div>
                <Button
                  onClick={handleClaimOld}
                  fullWidth
                  isLoading={loading}
                  disabled={oldSumAmountUsd.isZero()}
                >
                  Claim
                </Button>
              </div>
            </>
          )
          : (
            <>
              <Content>
                <Text fontSize='24px'>Your 404 rewards</Text>
                {
                  !gcetoInfo.availableToClaimTokens && account
                    ? <SkeletonLoader className={classes.loader}/>
                    : (
                      <Heading
                        color='secondaryBright' size={state.isXl || state.isXxl ? 'xl' : 'lg'}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        ${formatBNWithCommas(sumAmountUsd, 4, 0, true)}
                        {
                          !sumAmountUsd.isZero() && (
                            <QuestionHelper text={<ClaimValues availableToClaimTokens={gcetoInfo.availableToClaimTokens}/>}/>
                          )
                        }
                      </Heading>
                    )
                }
              </Content>

              <div>
                <Button
                  onClick={handleClaim}
                  fullWidth
                  isLoading={loading}
                  disabled={sumAmountUsd.isZero()}
                >
                  Claim
                </Button>
              </div>
            </>
          )
      }
    </Card>
  )
}

interface ClaimValuesProps {
  availableToClaimTokens: Record<string, { amount: BigNumber, amountUsd: BigNumber, symbol: string }>
}

const ClaimValues: FC<ClaimValuesProps> = ({ availableToClaimTokens }) => {

  return (
    <TextWrap>
      {
        availableToClaimTokens && Object.keys(availableToClaimTokens).map(item => (
          <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextSymbol>{availableToClaimTokens[item].symbol}</TextSymbol>
            {formatBNWithCommas(availableToClaimTokens[item].amount, 2, 0, true)}
            {' '}
            (${formatBNWithCommas(availableToClaimTokens[item].amountUsd, 2, 0, true)})
          </div>
        ))
      }
    </TextWrap>
  )
}

export default Claim
