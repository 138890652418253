import { JSBI, Percent, Token } from 'pulsex-sdk';
import { WETH } from 'sparkswap-sdk';
import { CHAIN_ID } from './chain';


export enum TOKEN_SYMBOL {
  USDEX_PLUS = 'USDEX+',
  USDC = 'USDC',
  EMP = 'EMP',
  DAI = 'DAI',
  WBNB = 'WBNB',
  BNB = 'BNB',
  BUSD = 'BUSD',
  sDAI = 'sDAI',
  FakeWETH = 'FakeWETH', // TODO TEST SYMBOL
  WETH = 'WETH',
  ETH = 'ETH',
  SPARK = 'SPARK',
  PLS = 'PLS',
  WPLS = 'WPLS',
  TRUTH = 'TRUTH',
  STGAI = 'STGAI',
  CETO = 'CETO',
  BCETO = 'bCETO',
  GCETO = 'gCETO',
  MANTA = 'MANTA',
  STONE = 'STONE',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const EMP_ADDRESSES = {
  [CHAIN_ID.BSC]: '0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
  // [CHAIN_ID.PULSE_CHAIN]: '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
}


export enum ROUTER {
  CETOSWAP,
  PULSEX,
  PANCAKESWAP,
}

export const ROUTER_ADDRESS = {
  [ROUTER.PANCAKESWAP]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ROUTER.CETOSWAP]: '0x882EE78CBA6676de8522f2c34bD08BacbCAEe813',
  [ROUTER.PULSEX]: '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02',
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in number | number]: Token[]
}

export const USDC_PLUS = new Token(CHAIN_ID.MANTA, '0x6Da9EbD271a0676F39C088a2b5fd849D5080c0af', 18, TOKEN_SYMBOL.USDEX_PLUS, 'USDEX+')
export const CETO = new Token(CHAIN_ID.MANTA, '0x3Af03E8C993900f0Ea6B84217071e1D4CC783982', 18, TOKEN_SYMBOL.CETO, 'CetoSwap')
export const B_CETO = new Token(CHAIN_ID.MANTA, '0xe68874E57224D1e4E6D4C6B4cF5AF7Ca51867611', 18, TOKEN_SYMBOL.BCETO, 'Burned Ceto')
export const G_CETO = new Token(CHAIN_ID.MANTA, '0x4C2A0F964A37a3cE305fe41C575BEeB48c8C3fA2', 18, TOKEN_SYMBOL.GCETO, 'gCETO404')
export const USDC = new Token(CHAIN_ID.MANTA, '0xb73603c5d87fa094b7314c74ace2e64d165016fb', 6, TOKEN_SYMBOL.USDC, 'USD Coin')
export const STGAI = new Token(CHAIN_ID.MANTA, '0x25f0Beb8876859008d00d457fA2AE6493F0fE020', 18, TOKEN_SYMBOL.STGAI, 'Strategic AI')
export const MANTA = new Token(CHAIN_ID.MANTA, '0x95cef13441be50d20ca4558cc0a27b601ac544e5', 18, TOKEN_SYMBOL.MANTA, 'Manta')
export const STONE = new Token(CHAIN_ID.MANTA, '0xec901da9c68e90798bbbb74c11406a32a70652c3', 18, TOKEN_SYMBOL.STONE, 'StakeStone Ether')
export const WETH_TOKEN = WETH[CHAIN_ID.MANTA]

export const PATH_TO_STABLE = {
  [TOKEN_SYMBOL.GCETO]: [G_CETO.address, B_CETO.address, WETH[CHAIN_ID.MANTA].address, USDC.address],
  [TOKEN_SYMBOL.WETH]: [WETH_TOKEN.address, USDC.address],
  [TOKEN_SYMBOL.MANTA]: [MANTA.address, WETH_TOKEN.address, USDC.address],
  [TOKEN_SYMBOL.STONE]: [STONE.address, WETH_TOKEN.address, USDC.address],
  [TOKEN_SYMBOL.USDEX_PLUS]: [USDC_PLUS.address, USDC.address],
}


const WETH_ONLY: ChainTokenList = {
  [CHAIN_ID.MANTA]: [USDC_PLUS],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [CHAIN_ID.MANTA]: [USDC_PLUS, USDC, MANTA, CETO, STGAI, WETH[CHAIN_ID.MANTA], G_CETO, B_CETO, STONE]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in number]?: { [tokenAddress: string]: Token[] } } = {
  [CHAIN_ID.MANTA]: {
    [CETO.address]: [WETH[CHAIN_ID.MANTA], MANTA, USDC, USDC_PLUS, STGAI],
    [MANTA.address]: [WETH[CHAIN_ID.MANTA], CETO, STGAI, USDC, USDC_PLUS],
    [USDC.address]: [WETH[CHAIN_ID.MANTA], CETO, USDC_PLUS, STGAI, MANTA],
    [STGAI.address]: [MANTA, CETO, WETH[CHAIN_ID.MANTA], USDC_PLUS, USDC],
    [USDC_PLUS.address]: [MANTA, CETO, WETH[CHAIN_ID.MANTA], STGAI, USDC],
    [G_CETO.address]: [WETH[CHAIN_ID.MANTA], B_CETO],
    [B_CETO.address]: [WETH[CHAIN_ID.MANTA], G_CETO],
    // [CETO.address]: [USDC_PLUS],
  },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [CHAIN_ID.MANTA]: [USDC_PLUS, CETO]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [CHAIN_ID.MANTA]: [USDC_PLUS, USDC, CETO, STGAI, MANTA, WETH[CHAIN_ID.MANTA], B_CETO, G_CETO, STONE],
}

export const PINNED_PAIRS: { readonly [chainId in number]?: [Token, Token][] } = {
  [CHAIN_ID.MANTA]: [
    [
      USDC,
      USDC_PLUS,
    ],
    [
      CETO,
      USDC_PLUS,
    ],
    [
      STGAI,
      MANTA,
    ],
    [
      WETH[CHAIN_ID.MANTA],
      CETO,
    ],
    [
      WETH[CHAIN_ID.MANTA],
      MANTA,
    ],
  ],
}
// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(5), JSBI.BigInt(18)) // 10,000 ETH

export const TOKENS = {
  [CHAIN_ID.BSC]: {
    [TOKEN_SYMBOL.TRUTH]: new Token(
      CHAIN_ID.BSC,
      '0x55a633b3fce52144222e468a326105aa617cc1cc',
      18,
      TOKEN_SYMBOL.TRUTH,
      'Truth Seekers'
    ),
    [TOKEN_SYMBOL.USDC]: new Token(
      CHAIN_ID.BSC,
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      18,
      TOKEN_SYMBOL.USDC,
      'USD Coin'
    ),
  },
  [CHAIN_ID.MANTA]: {
    [TOKEN_SYMBOL.USDEX_PLUS]: new Token(
      CHAIN_ID.MANTA,
      '0x6Da9EbD271a0676F39C088a2b5fd849D5080c0af',
      18,
      TOKEN_SYMBOL.USDEX_PLUS,
      'USDEX+'
    ),
    [TOKEN_SYMBOL.STGAI]: new Token(
      CHAIN_ID.MANTA,
      '0x25f0Beb8876859008d00d457fA2AE6493F0fE020',
      18,
      TOKEN_SYMBOL.STGAI,
      'Strategic AI'
    ),
    [TOKEN_SYMBOL.USDC]: new Token(
      CHAIN_ID.MANTA,
      '0xb73603c5d87fa094b7314c74ace2e64d165016fb',
      6,
      TOKEN_SYMBOL.USDC,
      'USD Coin'
    ),
    [TOKEN_SYMBOL.CETO]: new Token(
      CHAIN_ID.MANTA,
      '0x3af03e8c993900f0ea6b84217071e1d4cc783982',
      18,
      TOKEN_SYMBOL.CETO,
      'CetoSwap'
    ),
    [TOKEN_SYMBOL.WETH]: new Token(
      CHAIN_ID.MANTA,
      '0x0dc808adce2099a9f62aa87d9670745aba741746',
      18,
      TOKEN_SYMBOL.WETH,
      'Wrapped Ether'
    ),
    [TOKEN_SYMBOL.ETH]: new Token(
      CHAIN_ID.MANTA,
      '0x0dc808adce2099a9f62aa87d9670745aba741746',
      18,
      TOKEN_SYMBOL.ETH,
      'Ether'
    ),
  },
  [CHAIN_ID.ARBITRUM]: {
    [TOKEN_SYMBOL.USDEX_PLUS]: new Token(
      CHAIN_ID.ARBITRUM,
      '0x4117EC0A779448872d3820f37bA2060Ae0B7C34B',
      18,
      TOKEN_SYMBOL.USDEX_PLUS,
      'USDEX+'
    ),
    [TOKEN_SYMBOL.USDC]: new Token(
      CHAIN_ID.ARBITRUM,
      '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      6,
      TOKEN_SYMBOL.USDC,
      'USD Coin'
    ),
  },
}

export const TRX_LINK: Record<number, string> = {
  [CHAIN_ID.MANTA]: 'https://manta.socialscan.io/tx/',
};
