import React, { useMemo, FC } from 'react'
import Card from './Card'

import styled from 'styled-components'
import { Ceto404Types } from '../../hooks/use404Info'
import { formatBNWithCommas, toBN } from '../../../Bridge/utils/formaters.utils'
import { G_CETO } from '../../../../constants'


export const STATS_ITEMS = [
  {
    title: 'Total allocation',
    keyValue: 'totalAllocation',
    decimals: 0,
    prefix: undefined,
  },
  {
    title: 'Market cap',
    keyValue: 'marketCap',
    decimals: 0,
    prefix: '$',
  },
  {
    title: 'Price',
    keyValue: 'price',
    decimals: 0,
    prefix: '$',
  },
]

export const Box = styled.div`
  display: grid;
    grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  width: 100%;
  z-index: 2;


  ${({ theme }) => theme.mediaQueries.sm} {
      grid-template-columns: repeat(3, 1fr);
  }
`
interface Props {
  gcetoInfo: Ceto404Types,
}
const Stats: FC<Props> = ({ gcetoInfo}) => {

  const stats = useMemo(
    () => ({
      totalAllocation: gcetoInfo.totalSupply ? toBN(gcetoInfo.totalSupply, G_CETO.decimals).toString() : '0',
      marketCap: gcetoInfo.marketCap ? toBN(gcetoInfo.marketCap, G_CETO.decimals) : '0',
      price: gcetoInfo.price ? toBN(gcetoInfo.price) : '0',
    }), [gcetoInfo])

  return (
    <Box>
      {STATS_ITEMS.map((item) => (
        <Card {...item} key={item.keyValue} value={stats[item.keyValue]} />
      ))}
    </Box>
  )
}

export default Stats
