import B_CETO_ABI from './Bceto.json'
import G_CETO_404_ABI from './gCeto404.json';
import REVENUE_DISTRIBUTOR_ABI from './RevenueDistributor.json';
import REVENUE_DISTRIBUTOR_NEW_ABI from './RevenueDistributorNew.json';

// const REVENUE_DISTRIBUTOR = '0xC47bA06b541c0C49Ff84dc38aaF2B4f1e752E9C2'
const REVENUE_DISTRIBUTOR = '0x55141639a96EF928F45bcAc630803A97659c9c61';
const NEW_REVENUE_DISTRIBUTOR = '0x4bDd759E0456a0F3FB308e3d355e61AbE49762FF';

export {
  B_CETO_ABI,
  G_CETO_404_ABI,
  REVENUE_DISTRIBUTOR_ABI,
  REVENUE_DISTRIBUTOR,
  REVENUE_DISTRIBUTOR_NEW_ABI,
  NEW_REVENUE_DISTRIBUTOR,
}
