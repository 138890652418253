import React, { FC, memo, useCallback } from 'react'
import { Heading, Text } from 'sparkswap-uikit'
import { Token } from 'pulsex-sdk'

import Modal from 'components/Modal'
import CurrencyLogo from '../../../../components/CurrencyLogo'

import { BridgeData } from '../../types/bridge'

import { Column, Selectable } from '../../styles'

interface TokenBridgeModalProps {
  isOpen: boolean
  onDismiss: () => void
  data: BridgeData
  onClearInput: (value: string) => void
}

const TokenBridgeModal: FC<TokenBridgeModalProps> = ({ data, isOpen, onDismiss, onClearInput }) => {

  const handleChange = useCallback((token: Token) => {
    data.onChangeToken(token)
    onClearInput('');
    onDismiss()
  }, [data])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} minHeight={20}>
      <Column>
        <Heading padding="24px">Token Selection</Heading>
        {data?.tokens.map((token) => {
          if (data.token === token) return null
          return (
            <Selectable selected={data.token === token} key={token.symbol} onClick={() => handleChange(token)}>
              <CurrencyLogo currency={token} size="18px" style={{ marginLeft: '-4px', marginRight: '8px' }} />
              <Text>{token.symbol}</Text>
            </Selectable>
          )
        })}
      </Column>
    </Modal>
  )
}

export default memo(TokenBridgeModal)
