import styled from 'styled-components'
import { darken } from 'polished'

export const SelectLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSubtle};
  margin-bottom: 4px;
`

export const Select = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#FFFFFF')};
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :focus,
  :hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.input)};
  }
`
export const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InputPanel = styled.div<{ hideInput?: boolean }>`
  margin-bottom: 8px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
`

export const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(16px + 2rem);
  margin-top: -2rem;
  width: 100%;
  max-width: 420px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.colors.textSubtle};
  z-index: 1;
  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`

export const GridItem = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '16px')};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(auto, auto);
`

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 78px;
`

// Modal styles
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Selectable = styled.button<{ selected?: boolean }>`
  align-items: center;
  height: 60px;
  display: flex;
  font-size: 16px;
  color: ${({ selected, theme }) => (selected ? theme.colors.secondaryBright : theme.colors.text)};
  outline: none;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: 0;
  padding: 12px 24px;

  :hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`

export const EmptyBlock = styled.div`
  width: 18px;
`
