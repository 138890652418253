import React, { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import WrongNetworkModal from '../../components/WrongNetworkModal'

import { coinbaseConnection, injectedConnection, walletConnectV2Connection } from '../../connectors/connectors'

import { useEffectUpdate } from '../../hooks/useEffectUpdate'
import useWeb3 from '../../hooks/useWeb3'


import { useConnection } from '../../hooks/useConnection'
import { ConnectionType } from '../../constants/connectors.constants'
import { BRIDGE_PATH } from '../../constants/routes'
import { DEFAULT_CHAIN_ID } from '../../constants/chain'


type AuthContextType = {
  connectMetaMask: () => void;
  connectCoinbase: () => void;
  connectWalletConnect: (id: number) => void;
  logOut: () => void;
};

export const AuthorizationContext = createContext<null | AuthContextType>(null);

interface Props {
  children: ReactNode;
}

const AuthorizationContextProvider: FC<Props> = ({ children }) => {
  const { setConnectionInStorage, connectionInStorage } = useConnection();
  const { activate, deactivate, chainId, connector, account } = useWeb3();
  const { pathname } = useLocation();

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isNeedUpdate, setUpdate] = useState(false);

  const connectMetaMask = useCallback(async () => {
    try {
      await injectedConnection.connector.activate();

      setConnectionInStorage(ConnectionType.INJECTED);
    } catch (e) {
      console.error(e);
      setConnectionInStorage('');
    }
  }, []);

  const connectCoinbase = useCallback(async () => {
    try {
      await coinbaseConnection.connector.activate();

      setConnectionInStorage(ConnectionType.WALLET_LINK);
    } catch (e) {
      console.error(e);
      setConnectionInStorage('');
    }
  }, []);

  const connectWalletConnect = useCallback(async (id: number) => {
    try {
      // overwrites the chain id in constructor
      walletConnectV2Connection.overrideActivate(id);
      await activate(walletConnectV2Connection.connector, id);

      setUpdate(true);
      setConnectionInStorage(ConnectionType.WALLET_CONNECT_V2);
    } catch (e) {
      console.error(e);
      setConnectionInStorage('');
    }
  }, []);

  const logOut = useCallback(() => {
    deactivate();
    setConnectionInStorage('');

    window.indexedDB.databases().then((r) => {
      for (let i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
    }).then(() => { console.log('All data cleared.') });
  }, []);

  const isBridgePath = useMemo(() => (
    [BRIDGE_PATH].includes(pathname)
  ), [pathname])

  const isWrongNetwork = useMemo(() => (
    (!!account && chainId !== DEFAULT_CHAIN_ID && !isBridgePath) || !chainId
  ), [account, chainId, isBridgePath])

  useEffect(() => {
    (async () => {
      setIsWalletConnected(false);
      if (connectionInStorage === ConnectionType.INJECTED) {
        await connectMetaMask();
      }
      if (connectionInStorage === ConnectionType.WALLET_LINK) {
        await connectCoinbase();
      }
      if (connectionInStorage === ConnectionType.WALLET_CONNECT_V2) {
        walletConnectV2Connection.connector.connectEagerly();
      }
      setIsWalletConnected(true);
    })();
  }, []);

  useEffect(() => {
    if (isNeedUpdate) {
      (async () => {
        await connector.connectEagerly(walletConnectV2Connection.connector);
        setUpdate(false);
      })();
    }
  }, [isNeedUpdate, chainId]);

  useEffectUpdate(() => {
    if (!account) {
      setConnectionInStorage('');
    }
  }, []);

  return (
    <AuthorizationContext.Provider value={{ connectMetaMask, logOut, connectWalletConnect, connectCoinbase }}>
      {isWalletConnected ? children : <div />}
      {isWrongNetwork && <WrongNetworkModal />}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationContextProvider;
