import { initializeConnector, Web3ReactHooks } from '@web3-react/core';
import { Actions, Connector } from '@web3-react/types';

import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { WalletConnectV2 } from './connection/WalletConnectV2'
import { ConnectionType } from '../constants/connectors.constants'
import { CHAIN_ID, CHAINS_RPC, DEFAULT_CHAIN_ID } from '../constants/chain'



export interface Connection {
  getName(): string;
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
  overrideActivate?: (chainId?: CHAIN_ID) => boolean;
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, onError }),
);

export const injectedConnection: Connection = {
  getName: () => 'MetaMask',
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
};

const [coinbaseWallet, coinbaseHooks] = initializeConnector<CoinbaseWallet>((actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: CHAINS_RPC[CHAIN_ID.MANTA][0],
        appName: 'web3-react',
      },
    })
)

export const coinbaseConnection: Connection = {
  getName: () => 'Coinbase',
  connector: coinbaseWallet,
  hooks: coinbaseHooks,
  type: ConnectionType.WALLET_LINK,
};

export const walletConnectV2Connection: Connection = new (class implements Connection {
  private initializer = (actions: Actions, defaultChainId = DEFAULT_CHAIN_ID) =>
    new WalletConnectV2({ actions, defaultChainId, onError });

  type = ConnectionType.WALLET_CONNECT_V2;

  getName = () => 'WalletConnect';

  private _connector = initializeConnector<WalletConnectV2>(this.initializer);

  overrideActivate = (chainId?: CHAIN_ID) => {
    // Always re-create the connector, so that the chainId is updated.
    this._connector = initializeConnector((actions) => this.initializer(actions, chainId));
    return false;
  };

  get connector() {
    return this._connector[0];
  }

  get hooks() {
    return this._connector[1];
  }
})();
