import React from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light as oLight, PancakeTheme } from 'sparkswap-uikit'

const light = oLight as PancakeTheme;

const ThemeContextProvider: React.FC = ({ children }: any) => {

  return (
    <SCThemeProvider theme={light}>{children}</SCThemeProvider>
  )
}

export default ThemeContextProvider
