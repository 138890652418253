import React, {
  FC, memo, Suspense, useMemo,
} from 'react';

import SkeletonLoader from '../SkeletonLoader'
import useIpfsFile from '../../hooks/useIpfsFile'


export const IMAGE_LOAD_TIMEOUT_MS = 12000;

export interface IImageWithFallbackProps {
  cid: string;
  className?: string;
}

const Image: FC<IImageWithFallbackProps> = ({ cid, className }) => {
  const { response, error, loading } = useIpfsFile(cid);

  return (
    <>
      {
        error || loading || !response?.url
          ? <SkeletonLoader className={className} />
          : <img src={response?.url} className={className} alt="nft" />
      }
    </>
  );
};

Image.defaultProps = {
  className: '',
};

const ImageWithFallback: FC<IImageWithFallbackProps> = ({ className, ...props }) => (
  <Image className={className} {...props} />
);

ImageWithFallback.defaultProps = {
  className: '',
};

export default memo(ImageWithFallback);
