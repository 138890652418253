import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { ChevronDownIcon, Text } from 'sparkswap-uikit'

import TokenBridgeModal from '../TokenBridgeModal'
import CurrencyLogo from '../../../../components/CurrencyLogo'

import { BridgeData } from '../../types/bridge'

import { Aligner, EmptyBlock, InputPanel, Select, SelectLabel } from '../../styles'

type Props = {
  label: string
  data: BridgeData
  onClearInput: (value: string) => void
}

const SelectToken: FC<Props> = ({ label, data, onClearInput }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleDismiss = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const isDisabledSelect = useMemo(() => data?.tokens?.length <= 1, [data.tokens])

  return (
    <div>
      <SelectLabel>{label}</SelectLabel>
      <InputPanel>
        <Select
          selected={!!data?.token}
          className="open-currency-select-button"
          onClick={() => {
            if (!isDisabledSelect) {
              setModalOpen(true)
            }
          }}
        >
          <Aligner>
            {data?.token && <CurrencyLogo currency={data.token} size="18px" style={{ marginRight: '8px' }} />}
            <Text style={{ marginRight: 'auto' }}>{data?.token?.symbol}</Text>
            {isDisabledSelect ? <EmptyBlock color='secondaryBright' /> : <ChevronDownIcon color='secondaryBright' />}
          </Aligner>
        </Select>
      </InputPanel>
      {!isDisabledSelect && (
        <TokenBridgeModal isOpen={modalOpen} onClearInput={onClearInput} onDismiss={handleDismiss} data={data} />
      )}
    </div>
  )
}

export default memo(SelectToken)
