import { Trade } from 'sparkswap-sdk'
import React, { memo } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex, Text } from 'sparkswap-uikit'
import styled, { ThemeContext } from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'
import QuestionHelper from '../../../components/QuestionHelper'
import { RowFixed } from '../../../components/Row'
import { TYPE } from '../../../components/Shared'

const { black: Black } = TYPE

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primaryMedium};
  border-radius: 4px;
  padding: 24px;
`

export default memo(function SwapRoute({ trade }: { trade: Trade<any, any, any> }) {
  return (
    <Box>
      <RowFixed>
        <Text fontSize="14px">Route</Text>
        <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
      </RowFixed>
      <ThemeContext.Consumer>
        {(theme) => (
          <Flex
            flexWrap="wrap"
            alignItems="center"
            style={{ gap: '8px'}}
          >
            {trade.route.path.map((token, i, path) => {
              const isLastItem: boolean = i === path.length - 1
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <Flex marginBottom='4px' alignItems="center" style={{ flexShrink: 0 }}>
                    <CurrencyLogo currency={token} size="18px" />
                    <Black fontSize={14} color={theme?.colors.text} ml="0.5rem">
                      {token.symbol}
                    </Black>
                    {isLastItem ? null : <ChevronRight  size="18px" />}
                  </Flex>

                </div>
              )
            })}
          </Flex>
        )}
      </ThemeContext.Consumer>
    </Box>
  )
})
