import Web3 from 'web3';
import type BN from "bn.js";
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import IUniswapV2Router02ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { JSBI, Percent, Token, CurrencyAmount, Currency, ETHER, Pulse } from 'pulsex-sdk'
import { ROUTER, ROUTER_ADDRESS } from '../constants'
import { TokenAddressMap } from '../state/lists/hooks'
import { CHAIN_ID, GAS_MULTIPLIER, GAS_PRICE_MULTIPLIER } from '../constants/chain'
import { toBN } from '../pages/Bridge/utils/formaters.utils'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [CHAIN_ID.BSC]: 'bscscan.com',
  [CHAIN_ID.MANTA]: 'manta.socialscan.io',
  [CHAIN_ID.ARBITRUM]: 'arbiscan.io',
}

export function getEtherscanLink(chainId: number, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[56]}`

  switch (type) {
  case 'transaction': {
    return `${prefix}/tx/${data}`
  }
  case 'token': {
    return `${prefix}/token/${data}`
  }
  case 'address':
  default: {
    return `${prefix}/address/${data}`
  }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount<Token> | CurrencyAmount<Pulse>, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.quotient, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.quotient, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(chainId: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[ROUTER.PULSEX], IUniswapV2Router02ABI.abi, library, account)
}
// export function getRouterV2Contract(chainId: number, library: Web3Provider, account?: string): Contract {
//   return getContract(ROUTER_ADDRESS_V2[chainId], IUniswapV2Router02ABI.abi, library, account)
// }

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency?.isToken && defaultTokens[currency.chainId]?.[currency.address])
}

export const getTokenBalance = async (
  tokenContract: Contract,
  userAddress: string,
): Promise<string> => {
  try {
    const balance: string = await tokenContract.methods.balanceOf(userAddress).call()
    return balance
  } catch (e) {
    return '0'
  }
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type BaseContract = Omit<Contract, "clone" | "once">;
export interface EstimateGasOptions {
  from?: string;
  gas?: number;
  value?: number | string | BN;
}

export interface EventLog {
  event: string;
  address: string;
  returnValues: any;
  logIndex: number;
  transactionIndex: number;
  transactionHash: string;
  blockHash: string;
  blockNumber: number;
  raw?: { data: string; topics: any[] };
}

export interface TransactionReceipt {
  status: boolean;
  transactionHash: string;
  transactionIndex: number;
  blockHash: string;
  blockNumber: number;
  from: string;
  to: string;
  contractAddress?: string;
  cumulativeGasUsed: number;
  gasUsed: number;
  logs: Log[];
  logsBloom: string;
  events?: {
    [eventName: string]: EventLog;
  };
}
export interface Log {
  address: string;
  data: string;
  topics: string[];
  logIndex: number;
  transactionIndex: number;
  transactionHash: string;
  blockHash: string;
  blockNumber: number;
}

export async function web3SendTxWrap<
  C extends BaseContract,
  M extends keyof PropType<C, 'methods'>,
>(
  contract: C,
  methodName: M,
  params: any[],
  options: EstimateGasOptions,
  library: any,
  chainId?: number,
): Promise<TransactionReceipt> {
  const web3 = new Web3(library.provider);
  const gasPrice = await web3.eth.getGasPrice();
  const chainGasPrice = toBN(gasPrice).times(GAS_PRICE_MULTIPLIER[chainId]).toFixed(0);
  let adjustedGas;
  try {
    const gas = await contract.methods[methodName](...params).estimateGas({
      ...options,
      gasPrice: chainGasPrice,
    });
    adjustedGas = toBN(gas).times(GAS_MULTIPLIER[chainId]).toFixed(0);
  } catch (e) {
    console.log(e);
  }

  return new Promise((res, rej) => {
    contract.methods[methodName](...params)
      .send({ ...options, gas: adjustedGas, gasPrice: chainGasPrice })
      .once('receipt', async (receipt: TransactionReceipt) => {
        return res(receipt);
      })
      .on('error', (error) => {
        return rej(error);
      });
  });
}

export function isAddressesEqual(address0: string, address1: string): boolean {
  if (!address0 || !address1) return false;
  return address0.toLowerCase() === address1.toLowerCase();
}
