import { useCallback, useEffect, useState } from 'react'
import { Token } from 'sparkswap-sdk'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { MultiCall } from 'multicall.js'

import useWeb3 from './useWeb3'

import { makeContract } from '../pages/Bridge/utils/contract.utils'
import { applyDecimals, toBN } from '../pages/Bridge/utils/formaters.utils'

import DexSwapAbi from '../constants/abis/DexSwapRouterAbi.json'

import { DEFAULT_CHAIN_ID, MULTI_CALL } from '../constants/chain'
import { PATH_TO_STABLE, ROUTER, ROUTER_ADDRESS, TOKEN_SYMBOL, TOKENS, ZERO_ADDRESS } from '../constants'


const useGetTokensPrices = (tokensIn: Token[]) => {
  const [price, setPrice] = useState<{[value: string]: BigNumber}>(null)
  const { libraryByChainId } = useWeb3()

  const getPrice = useCallback(async () => {
    const usdc = TOKENS[DEFAULT_CHAIN_ID][TOKEN_SYMBOL.USDC];
    const web3 = new Web3(libraryByChainId(DEFAULT_CHAIN_ID).provider);
    const multiCall = new MultiCall(web3, MULTI_CALL);
    const routerContract = makeContract(libraryByChainId(DEFAULT_CHAIN_ID), DexSwapAbi, ROUTER_ADDRESS[ROUTER.CETOSWAP]);

    const targetsAmounts = tokensIn.map(item => (
      applyDecimals(1 / 100, item.decimals).toString()
    ))

    const [lpsTotalSupplyResult] = await multiCall.all([
      tokensIn.map((item, index) => ({
        amounts: routerContract.methods.getAmountsOut(targetsAmounts[index], PATH_TO_STABLE[item.symbol], ZERO_ADDRESS),
      })),
    ]);

    const prices = tokensIn.reduce((acc, item, index) => {
      const amounts = lpsTotalSupplyResult[index].amounts;
      const a1Raw = toBN(amounts[amounts.length - 1]);
      return {
        ...acc,
        [item.symbol]: toBN(a1Raw.times(100), usdc.decimals)
      }
    }, {})

    setPrice(prices);
  },[])

  useEffect(() => {
    (async () => {
      try {
        await getPrice();
      } catch (e) {
        console.log('TokenPrice', e)
      }

    })()
  }, [])

  return price;
}

export default useGetTokensPrices;