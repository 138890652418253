import { Connector } from '@web3-react/types';

import {  injectedConnection, walletConnectV2Connection, coinbaseConnection } from './connectors'
import { ConnectionType } from '../constants/connectors.constants'


export const getConnections = () => [walletConnectV2Connection, injectedConnection, coinbaseConnection];

export const getConnection = (c: Connector | ConnectionType) => {
  if (c instanceof Connector) {
    const connection = getConnections().find((con) => con.connector === c);
    if (!connection) {
      throw Error('unsupported connector');
    }
    return connection;
  }
    switch (c) {
      case ConnectionType.INJECTED:
        return injectedConnection;
      case ConnectionType.WALLET_LINK:
        return coinbaseConnection;
      case ConnectionType.WALLET_CONNECT_V2:
        return walletConnectV2Connection;
      default: return injectedConnection;
    }
};
