import React from 'react'
import styled from 'styled-components'

import { Box } from '../AppBody'

import Claim from './components/Claim'
import Stats from './components/Stats'
import MyNfts from './components/MyNfts'
import GCETO404 from './components/gCETO404'
import Exchange from './components/Exchange'

import use404Info from './hooks/use404Info'


export const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: auto 330px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    grid-template-columns: auto 440px;
  }
`

export const Wrapper = styled.div`
  max-width: 1440px;
`

const Ceto404 = () => {
  console.log('dd')

  const { info: gcetoInfo, oldInfo, update404Info } = use404Info()

  return (
    <Box>
      <Wrapper>
        <Stats gcetoInfo={gcetoInfo}/>
        <Inner>
          <div>
            <GCETO404 />
            <Claim gcetoInfo={gcetoInfo} update404Info={update404Info} oldInfo={oldInfo}/>
          </div>
          <Exchange />
        </Inner>
        <MyNfts gcetoInfo={gcetoInfo}/>
      </Wrapper>
    </Box>
  )
}

export default Ceto404
