import React, { FC, memo, useMemo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface Props {
  className?: string;
  count?: number;
  isDark?: boolean
}

const SkeletonLoader: FC<Props> = ({ className, count, isDark }) => {
  const loaderCount = useMemo(() => (
    Array.from({ length: count ?? 0 }, (_, index) => index + 1)
  ), [count]);

  const colors = useMemo(() => ({
    baseColor: '#050317',
    highlightColor: '#130a41',
  }), []);

  return (
    <>
      {count ? (
        <SkeletonTheme baseColor={colors.baseColor} highlightColor={colors.highlightColor} inline>
          {loaderCount.map((item) => (
            <Skeleton className={className} key={String(item)} inline />
          ))}
        </SkeletonTheme>
      ) : (
        <SkeletonTheme baseColor={colors.baseColor} highlightColor={colors.highlightColor} inline>
          <Skeleton className={className} inline />
        </SkeletonTheme>
      )}
    </>
  );
};

SkeletonLoader.defaultProps = {
  className: '',
  count: undefined,
  isDark: false,
};

export default memo(SkeletonLoader);
