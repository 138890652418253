import { NavEntry, SocialEntry } from 'sparkswap-uikit'
import { CETO_404_PATH } from '../../constants/routes'

export const config: NavEntry[] = [
  {
    label: 'Home',
    href: 'https://cetoswap.com/',
  },
  {
    label: 'Ceto404',
    href: CETO_404_PATH,
  },
  {
    label: 'Trade',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: `/swap?outputCurrency=${''}`
      },
      {
        label: 'Liquidity',
        href: '/pool'
      },

    ]
  },
  {
    label: 'Farms',
    href: 'https://cetoswap.com/#/farms'
  },
  {
    label: 'Bridge',
    href: '/bridge'
  },
  {
    label: 'Vaults',
    href: 'https://www.dexfinance.com/vaults?chainFilter=%5B169%5D'
  },
  {
    label: 'Marketplace',
    initialOpenState: true,
    items: [
      {
        label: 'Collection',
        href: `https://marketplace.cetoswap.com/collections/2`
      },
      {
        label: 'Dashboard',
        href: 'https://marketplace.cetoswap.com/dashboard'
      },
    ]
  },
  {
    label: 'Perpetuals',
    items: [
      {
        label: 'Perpetuals',
        href: `https://perpetuals.cetoswap.com`,
      },
      {
        label: 'SLP Vault',
        href: `https://perpetuals.cetoswap.com/vault`,
      },
      {
        label: 'Stats',
        href: `https://perpetuals.cetoswap.com/stats`,
      },
      {
        label: 'Daily Trades',
        href: `https://perpetuals.cetoswap.com/daily-trades`,
      },
    ],
  },
]

export const socials: SocialEntry[] = [
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/cetoswapmanta',
  },
  {
    label: 'Discord',
    icon: 'DiscordIcon',
    href: 'https://discord.gg/cetoswapmanta',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/cetoswapmanta',
  },
];
