import { G_CETO, USDC, WETH_TOKEN, MANTA, STONE, USDC_PLUS } from '../../../constants'

export enum NftClass {
  KRAKEN,
  FISH_MAN,
  SHARK,
  SEA_SERPENT,
  GODZILLA,
  HYDRA
}

export const NFT_CLASS_NAME = {
  [NftClass.KRAKEN]: '404 Reflections',
  [NftClass.FISH_MAN]: 'Swap fees',
  [NftClass.SHARK]: 'Bridge fees',
  [NftClass.SEA_SERPENT]: 'Bot revenue',
  [NftClass.GODZILLA]: 'Vaults fees',
  [NftClass.HYDRA]: 'Perpetual trading fees',
}

// USDEX+/USDC - 0x3838753b9B9B7cf26ddc71d0F1a0Ee0b9144ABA5
// CETO/USDEX+ - 0xEF799451a28A1432712d19dF82e512d985D685D4
// WETH/MANTA - 0xB9454b5FC78C826cF028F4e513b0E0a4d2bF51a9
// STGAI/MANTA - 0x75791775DC127D4441ecc532be128214CA4c6f72
// CETO/WETH - 0x6c72baEE6D7074E54ADD3f617ec5D6b22aFe779b
// USDC/WETH - 0xd6359083da64e79ad7c54106ee71a889268f0462
// bCeto/WETH - 0x3afed5925034bb3b730ffdae6d98b6df45c0ff74
// STONE/WETH - 0x05db409bc3c2629e2987dc7aa87c39376bc49477
// gCeto/bCeto - 0xa4a9082ceb55539d05a5638d372c5c686bf2183b

export const LP_LIST = {
  ['0x3838753b9B9B7cf26ddc71d0F1a0Ee0b9144ABA5'] : {
    symbol: 'USDEX+/USDC',
    token0: '0x6da9ebd271a0676f39c088a2b5fd849d5080c0af',
    token1: '0xb73603c5d87fa094b7314c74ace2e64d165016fb',
    quoteToken: '0xb73603c5d87fa094b7314c74ace2e64d165016fb', // USDC
  },
  ['0xEF799451a28A1432712d19dF82e512d985D685D4'] : {
    symbol: 'CETO/USDEX+',
    token0: '0x3af03e8c993900f0ea6b84217071e1d4cc783982',
    token1: '0x6da9ebd271a0676f39c088a2b5fd849d5080c0af',
    quoteToken: '0x6da9ebd271a0676f39c088a2b5fd849d5080c0af', // USDEX+
  },
  ['0xB9454b5FC78C826cF028F4e513b0E0a4d2bF51a9'] : {
    symbol: 'WETH/MANTA',
    token0: '0x0dc808adce2099a9f62aa87d9670745aba741746',
    token1: '0x95cef13441be50d20ca4558cc0a27b601ac544e5',
    quoteToken: '0x0dc808adce2099a9f62aa87d9670745aba741746', // WETH
  },
  ['0x75791775DC127D4441ecc532be128214CA4c6f72'] : {
    symbol: 'STGAI/MANTA',
    token0: '0x25f0beb8876859008d00d457fa2ae6493f0fe020',
    token1: '0x95cef13441be50d20ca4558cc0a27b601ac544e5',
    quoteToken: '0x95cef13441be50d20ca4558cc0a27b601ac544e5', // MANTA
  },
  ['0x6c72baEE6D7074E54ADD3f617ec5D6b22aFe779b'] : {
    symbol: 'CETO/WETH',
    token0: '0x0dc808adce2099a9f62aa87d9670745aba741746',
    token1: '0x3af03e8c993900f0ea6b84217071e1d4cc783982',
    quoteToken: '0x0dc808adce2099a9f62aa87d9670745aba741746', // WETH
  },
  ['0xd6359083da64e79ad7c54106ee71a889268f0462'] : {
    symbol: 'USDC/WETH',
    token0: '0x0dc808adce2099a9f62aa87d9670745aba741746',
    token1: '0xb73603c5d87fa094b7314c74ace2e64d165016fb',
    quoteToken: '0x0dc808adce2099a9f62aa87d9670745aba741746', // WETH
  },

  ['0x05db409bc3c2629e2987dc7aa87c39376bc49477'] : {
    symbol: 'STONE/WETH',
    token0: '0x0dc808adce2099a9f62aa87d9670745aba741746',
    token1: '0xec901da9c68e90798bbbb74c11406a32a70652c3',
    quoteToken: '0x0dc808adce2099a9f62aa87d9670745aba741746', // WETH
  },
  // ['0xa4a9082ceb55539d05a5638d372c5c686bf2183b'] : {
  //   name: 'gCeto/bCeto',
  //   token0: '0x4c2a0f964a37a3ce305fe41c575beeb48c8c3fa2',
  //   token1: '0xe68874e57224d1e4e6d4c6b4cf5af7ca51867611',
  //   quoteToken: '0x4c2a0f964a37a3ce305fe41c575beeb48c8c3fa2', // gCETo
  // },
  // ['0x3afed5925034bb3b730ffdae6d98b6df45c0ff74'] : {
  //   name: 'bCeto/WETH',
  //   token0: '0x0dc808adce2099a9f62aa87d9670745aba741746',
  //   token1: '0xe68874e57224d1e4e6d4c6b4cf5af7ca51867611',
  //   quoteToken: '0x0dc808adce2099a9f62aa87d9670745aba741746', // WETH
  // },
}


export const CLAIM_TOKENS = {
  [G_CETO.address]: G_CETO,
  [WETH_TOKEN.address]: WETH_TOKEN,
  [MANTA.address]: MANTA,
  [STONE.address]: STONE,
  [USDC_PLUS.address]: USDC_PLUS,
}