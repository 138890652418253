import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { TOAST_DEFAULT_OPTIONS, TOAST_TYPE } from '../../constants/toast.constants'

import { dFlex } from '../../style/Global'

import { getEtherscanLink } from '../../new/utils'

import ToastIcon from '../../assets/images/toast.svg';

type ToastContent = {
  title: string
  description?: string
  withLink?: {
    hash: string
    chainId: number
  }
}


const showToast = (
  content: ToastContent,
  type: TOAST_TYPE,
  toastOptions?: {
    hideIcon?: boolean
    hideClose?: boolean
    showProgressBar?: boolean
  },
) => {
  const options: ToastOptions = {
    ...TOAST_DEFAULT_OPTIONS,
    type,
    className: `toast-container-${type}`,
    icon: !toastOptions?.hideIcon && <img src={ToastIcon} />,
    closeButton: !toastOptions?.hideClose,
    hideProgressBar: !toastOptions?.showProgressBar,
  }

  const renderBlock = () => (
    <div style={{ ...dFlex('space-between', 'flex-start'), flexDirection: 'column', gap: '8px' }}>
      <div>
        <Typography variant="body1" color="#ffff">
          {content.title}
        </Typography>
        {content?.description && (
          <Typography variant="body2" color="#ffff" style={{ marginTop: '4px', ...dFlex() }}>
            {content.description}
          </Typography>
        )}
      </div>
      {content?.withLink && (
        <a
          target="_blank"
          style={{ color: '#D501BB', fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '4px' }}
          className="toastIcon"
          href={getEtherscanLink(content.withLink.chainId, content.withLink.hash, 'transaction')}
        >
          View on Explorer
          <OpenInNewIcon style={{ color: '#D501BB' }} fontSize='inherit' />
        </a>
      )}
    </div>
  )

  toast(renderBlock(), options)
}

export default showToast
