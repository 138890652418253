import CoinLogo from 'components/pancake/CoinLogo'
import React from 'react'
import { Card, Heading, Text } from 'sparkswap-uikit'
import styled from 'styled-components'


const DATA = [
  {
    text: 'gCETO404 will feature a collection of 10,000 unique NFTs broken down into six distinct classifications representing our core revenue streams (404 reflections, swap fees, bridge fees, vault fees and perpetual trading fees).'
  },
  {
    text: 'Each category will be defined by a different sea creature and will have additional rarity traits potentially making various NFTs within a given category more valuable than others on 3rd party markets.'
  },
  {
    text: 'Collect all six sea creatures and you’ll be granted access to mint the ultra rare goddess CETO NFT which unlocks additional bonuses!',
    isAccent: true
  }
]

export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

`

export const Logo = styled.img`
  width: 48px;
  height: 48px;
`

const GCETO404 = () => {
  return (
    <Card padding={24} marginTop={30} style={{ zIndex: 2 }}>
      <Header>
        <Logo src={'/images/coins/gCETO.png'} />
        <Heading size='lg'>gCETO404</Heading>
      </Header>

      <Content>
        {DATA.map(({text, isAccent}) => (
          <Text fontSize='16px' bold={isAccent} color={isAccent ? '#26FFFE' : '#E9E9E9'} key={text}>{text}</Text>
        ))}
      </Content>
    </Card>
  )
}

export default GCETO404
