import { useCallback, useState } from 'react'

import showToast from '../../../components/Toast'

import useWeb3 from '../../../hooks/useWeb3'
import { web3SendTxWrap } from '../../../utils'

import { makeContract } from '../../Bridge/utils/contract.utils'

import {
  REVENUE_DISTRIBUTOR,
  REVENUE_DISTRIBUTOR_ABI,
  REVENUE_DISTRIBUTOR_NEW_ABI,
  NEW_REVENUE_DISTRIBUTOR
} from '../../../constants/abis/ceto404'
import { TOAST_TYPE } from '../../../constants/toast.constants'


const useClaim = () => {
  const [loading, setLoading] = useState(false)
  const { library, chainId, account } = useWeb3()

  const claim = useCallback(async (ids: string[], isOld?: boolean) => {
    try {
      setLoading(true)

      const contract = isOld
        ? makeContract(library, REVENUE_DISTRIBUTOR_ABI, REVENUE_DISTRIBUTOR)
        : makeContract(library, REVENUE_DISTRIBUTOR_NEW_ABI, NEW_REVENUE_DISTRIBUTOR)

      const args = isOld ? ids : [...ids, 0]

      const recipient = await web3SendTxWrap(
        contract,
        'claim',
        [args],
        { from: account },
        library,
        chainId,
      )

      showToast(
        {
          title: `Claimed`,
          withLink: {
            chainId: chainId,
            hash: recipient.transactionHash,
          },
        },
        TOAST_TYPE.SUCCESS,
        {
          showProgressBar: true
        }
      )
    } catch (e) {
      showToast(
        {
          title: `Denied Transaction`,
        },
        TOAST_TYPE.ERROR,
        {
          showProgressBar: true
        }
      )
    } finally {
      setLoading(false)
    }

  },[library, chainId])

  return {
    loading,
    claim,
  }
}

export default useClaim;