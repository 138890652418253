export const SWAP_PATH = '/swap';
export const CETO_404_PATH = '/ceto404';

export const SWAP_OUTPUT_CURRENCY_PATH = '/swap/:outputCurrency';

export const SEND_PATH = '/send';

export const BRIDGE_PATH = '/bridge';

export const FIND_PATH = '/find';

export const POOL_PATH = '/pool';

export const CREATE_PATH = '/create';

export const ADD_PATH = '/add';

export const ADD_CURRENCY_ID_A = '/add/:currencyIdA';

export const ADD_CURRENCY_ID_A_AND_B = '/add/:currencyIdA/:currencyIdB';

export const REMOVE_TOKENS_PATH = '/remove/:tokens';

export const REMOVE_CURRENCY_ID_A_AND_B = '/remove/:currencyIdA/:currencyIdB';
