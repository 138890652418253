import { useSelector } from 'react-redux'
import { AppState } from '../index'
import useWeb3 from '../../../hooks/useWeb3'

export function useBlockNumber(): number | undefined {
  const { chainId } = useWeb3()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export default useBlockNumber;
