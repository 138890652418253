import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, isAddress, shortenAddress } from '../utils'
import isZero from '../utils/isZero'
import { useBridgeContract } from './useContract'
import useENS from './useENS'
import { BridgeData, BridgeDataFrom } from "../pages/Bridge/types/bridge";
import useWeb3 from './useWeb3'

export enum CallbackState {
  INVALID,
  LOADING,
  VALID,
}
type useBridgeCallbackParams = {
  from: BridgeDataFrom
  to: BridgeData
  amount: number | string | BigNumber,
  recipientAddressOrName: string | null
}

export function useBridgeCallback({
  from,
  to,
  amount,
  recipientAddressOrName,
}: useBridgeCallbackParams): { state: CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library: provider } = useWeb3()
  const contract = useBridgeContract()

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = !recipientAddressOrName ? account : recipientAddress
  const target = recipient && (recipient === account ? account : recipient);

  const tokenId = new BigNumber(from.bridgeTokenId);
  const currency = from.token;
  const toChain = to.chain;

  const onBridge =  useMemo(() => {
    if (!currency || !tokenId || !provider || !account || !chainId || !contract || !toChain || !amount) {
      return { state: CallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient || !target) {
      if (recipientAddressOrName !== null) {
        return { state: CallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      }
      return { state: CallbackState.LOADING, callback: null, error: null }
    }

    return {
      state: CallbackState.VALID,
      callback: async function onBridge(): Promise<string> {
        const args = [
          tokenId.toString(),
          new BigNumber(amount).times(new BigNumber(10).pow(currency.decimals)).toFixed(),
          toChain.toString(),
          target,
        ]
        const value = '0'
        const methodName = 'create'
        const gasEstimate = await contract.estimateGas[methodName](...args, !value || isZero(value) ? {} : { value })

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...(value && !isZero(value) ? { value, from: account } : { from: account }),
        })
          .then((response: any) => {
            const inputSymbol = currency.symbol
            const inputAmount = new BigNumber(amount).toFormat()

            const base = `Bridge ${inputAmount} ${inputSymbol}`
            const withRecipient =
                  recipient === account
                    ? base
                    : `${base} to ${recipientAddressOrName && isAddress(recipientAddressOrName)
                      ? shortenAddress(recipientAddressOrName)
                      : recipientAddressOrName
                    }`

            addTransaction(response, {
              summary: withRecipient,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, methodName, args, value)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [tokenId, currency, amount, provider, account, chainId, recipient, target, recipientAddressOrName, contract, toChain, addTransaction])


  const onBridgeWithSwap = useMemo(() => {
    if (!currency || !tokenId || !provider || !account || !chainId || !contract || !toChain || !amount) {
      return { state: CallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }

    if (!recipient || !target) {
      if (recipientAddressOrName !== null) {
        return { state: CallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      }
      return { state: CallbackState.LOADING, callback: null, error: null }
    }

    return {
      state: CallbackState.VALID,
      callback: async function onBridge(): Promise<string> {
        const amountIn  =  new BigNumber(amount).times(new BigNumber(10).pow(currency.decimals)).toFixed()

        const args = [
          from.token?.address,
          amountIn,
          toChain.toString(),
          target,
          to.token?.address,
          from.fromSwapPath,
          from.payInNative,
        ]

        const value = from.isNative ? amountIn : '0'
        const methodName = 'createWithSwap'
        const gasEstimate = await contract.estimateGas[methodName](...args, !value || isZero(value) ? {} : { value })

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...(value && !isZero(value) ? { value, from: account } : { from: account }),
        })
          .then((response: any) => {
            const inputSymbol = currency.symbol
            const inputAmount = new BigNumber(amount).toFormat()

            const base = `Bridge ${inputAmount} ${inputSymbol}`
            const withRecipient =
                  recipient === account
                    ? base
                    : `${base} to ${recipientAddressOrName && isAddress(recipientAddressOrName)
                      ? shortenAddress(recipientAddressOrName)
                      : recipientAddressOrName
                    }`

            addTransaction(response, {
              summary: withRecipient,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, methodName, args, value)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    tokenId,
    currency,
    amount,
    provider,
    account,
    chainId,
    recipient,
    target,
    recipientAddressOrName,
    contract,
    toChain,
    addTransaction
  ])


  return from.isBridge ? onBridge : onBridgeWithSwap;
}

