import useWeb3 from '../../../hooks/useWeb3'
import { useState } from 'react'
import { web3SendTxWrap } from '../../../utils'
import { makeContract } from '../../Bridge/utils/contract.utils'
import { applyDecimals } from '../../Bridge/utils/formaters.utils'

import showToast from '../../../components/Toast'

import { TOAST_TYPE } from '../../../constants/toast.constants'
import { B_CETO_ABI } from '../../../constants/abis/ceto404'
import { CETO, B_CETO } from '../../../constants'


const useExchange = () => {
  const [loading, setLoading] = useState(false)
  const { library, chainId, account } = useWeb3()

  const onExchange = async (amount: string) => {
    try {
      setLoading(true)
      const contract = makeContract(library, B_CETO_ABI, B_CETO.address)

      const amountContract = applyDecimals(amount, CETO.decimals).toFixed(0)

      const recipient = await web3SendTxWrap(
        contract,
        'exchange',
        [amountContract],
        { from: account },
        library,
        chainId,
      )

      showToast(
        {
          title: `Burn ${amount}${CETO.symbol} to ${B_CETO.symbol}`,
          withLink: {
            chainId: chainId,
            hash: recipient.transactionHash,
          },
        },
        TOAST_TYPE.SUCCESS,
        {
          showProgressBar: true
        }
      )
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    onExchange,
    loading,
  }
}

export default useExchange
