import React, { FC, memo, useCallback, useMemo } from 'react'
import { ChevronDownIcon, Text } from 'sparkswap-uikit'
import { CHAIN_ICON, CHAIN_NAME } from '../../../../constants/chain'

import { BridgeData } from '../../types/bridge'

import { Aligner, EmptyBlock, InputPanel, Select, SelectLabel } from '../../styles'

type Props = {
  label: string
  onOpenModal: () => void
  data: BridgeData
}

const SelectButton: FC<Props> = ({ data, label, onOpenModal }) => {
  const isDisabledSelect = useMemo(() => data?.chains?.length <= 1, [data])

  const handleOpenModal = useCallback(() => {
    if (isDisabledSelect) return

    onOpenModal()
  }, [isDisabledSelect])

  return (
    <div>
      <SelectLabel>{label}</SelectLabel>
      <InputPanel>
        <Select selected={!!data.chain} className="open-currency-select-button" onClick={handleOpenModal}>
          <Aligner>
            <img src={CHAIN_ICON[data.chain]} alt="chain logo" width={18} style={{ marginRight: '8px' }} />
            <Text style={{ marginRight: 'auto' }}>{CHAIN_NAME[data.chain]}</Text>
            {isDisabledSelect ? <EmptyBlock  color='secondaryBright' /> : <ChevronDownIcon  color='secondaryBright'/>}
          </Aligner>
        </Select>
      </InputPanel>
    </div>
  )
}

export default memo(SelectButton)
