import React from 'react'
import { Button, ButtonProps, ConnectorId, useWalletModal } from 'sparkswap-uikit'

import useWeb3 from '../../hooks/useWeb3'
import { useAuthMethod } from '../../hooks/useAuth'
import { CONNECTOR_ID } from '../../constants/connectors.constants'


const UnlockButton: React.FC<ButtonProps> = props => {
  const { account, chainId } = useWeb3();
  const { connectMetaMask, connectWalletConnect, connectCoinbase, logOut } = useAuthMethod();

  const handleLogin = (connectorId: ConnectorId) => {
    switch (connectorId) {
      case CONNECTOR_ID.METAMASK:
        return connectMetaMask();
      case CONNECTOR_ID.WALLET_LINK:
        return connectCoinbase();
      case CONNECTOR_ID.WALLET_CONNECT:
        return connectWalletConnect(chainId);
      default:
        return connectMetaMask();
    }
  }


  const { onPresentConnectModal } = useWalletModal(handleLogin, logOut, account as string)

  return (
    <Button onClick={onPresentConnectModal} {...props}>
      Unlock Wallet
    </Button>
  )
}

export default UnlockButton
