import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import Container from './Container'
import img from './images/bg-bottom.png'

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

const PageWrap = styled(Container)`
  margin: 0 auto;
  position: relative;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const BgLeft = styled.div`
  position: absolute;
  left: -400px;
  top: -150px;
  z-index: 1;
  pointer-events: none;
`

const BgRight = styled.div`
  position: absolute;
  right: -400px;
  top: -100px;
  z-index: 1;
  pointer-events: none;
`

const BgBottom = styled.div`
  background-repeat: repeat-x;
  background-image: url(${img});
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 300px;
  z-index: 1;
  pointer-events: none;
`

const Page: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Wrapper>
      <PageWrap>
        <BgRight>
          <img src="/images/bgRightSecondary.png" alt="bg" />
        </BgRight>
        <BgLeft>
          <img src="/images/bgLeftSecondary.png" alt="bg" />
        </BgLeft>
        {children}
      </PageWrap>
      <BgBottom />
    </Wrapper>
  )
}

export default Page
