import React, { FC, memo, ReactNode } from 'react'
import { Card as CardUI, Text } from 'sparkswap-uikit'

import CardValue from './CardValue'

interface Props {
  title: string | ReactNode
  value: number
  decimals?: number
  prefix?: string
}

const Card: FC<Props> = ( { title, prefix, value, decimals }) => {

  return (
    <CardUI padding={24} style={{ textAlign: 'center', zIndex: 2 }}>
      <Text fontSize='24px' paddingBottom='16px'>
        {title}
      </Text>
      <CardValue size='lg' value={value} decimals={decimals} prefix={prefix} />
    </CardUI>
  )
}

export default memo(Card)
