import React, { useEffect, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import { Connector } from '@web3-react/types'
import { ModalProvider } from 'sparkswap-uikit'
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import ThemeContextProvider from './ThemeContext'
import store from './state'
import { useOrderedConnections } from './hooks/useOrderedConnections'

import Web3ContextProvider from './contexts/Web3Context'
import AuthorizationContextProvider from './contexts/AuthorizationContext'

import { ToastContainer } from 'react-toastify'
import { TOAST_DEFAULT_AUTO_CLOSE_TIME, TOAST_DEFAULT_POSITION } from './constants/toast.constants'

export default function Providers({ children }: any) {
  const connections = useOrderedConnections();

  const connectors: [Connector, Web3ReactHooks][] = connections.map(({ hooks, connector }) => [
    connector,
    hooks,
  ]);

  const [index, setIndex] = useState(0);

  useEffect(() => setIndex((ind) => ind + 1), [connections]);

  const key = useMemo(() => (
    connections.map((connection) => connection.getName()).join('-') + index
  ), [connections, index]);

  return (
    <Web3ReactProvider connectors={connectors} key={key + new Date()}>
      <Provider store={store}>
        <Web3ContextProvider>
          <AuthorizationContextProvider>
            <ThemeContextProvider>
              <ModalProvider>
                {children}
                <ToastContainer
                  position={TOAST_DEFAULT_POSITION}
                  autoClose={TOAST_DEFAULT_AUTO_CLOSE_TIME}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  icon={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </ModalProvider>
            </ThemeContextProvider>
          </AuthorizationContextProvider>
        </Web3ContextProvider>
      </Provider>
    </Web3ReactProvider >
  )
}
