export default function chunkArray<T>(items: T[], maxChunkSize: number): T[][] {
  if (maxChunkSize < 1) throw new Error('maxChunkSize must be gte 1')
  if (items.length <= maxChunkSize) return [items]

  const numChunks: number = Math.ceil(items.length / maxChunkSize)
  const chunkSize = Math.ceil(items.length / numChunks)

  // Convert the iterator to an array before mapping
  const indices = Array.from(Array(numChunks).keys())

  return indices.map(ix => items.slice(ix * chunkSize, ix * chunkSize + chunkSize))
}
